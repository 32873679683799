import React, { useEffect, useState } from "react";
import me from "./img/me.gif";
import { ArrowClockwise, ArrowSquareOut, At, GithubLogo } from "@phosphor-icons/react";
import md5 from "md5";
import { LinkedinLogo } from "@phosphor-icons/react/dist/ssr";

const mapLanguages = (language) => {
  switch (language) {
    case "JavaScript":
      return "js";
    case "C++":
      return "cpp";

    default:
      return "gn";
  }
};

const Repo = ({ name, description, url, language, demo }) => {
  const [avatar, setAvatar] = useState();
  useEffect(() => {
    if (url) {
      const hash = md5(url);
      fetch(`https://secure.gravatar.com/avatar/${hash}?d=identicon&s=32`, {
        method: "GET",
        redirect: "follow",
      })
        .then((response) => response.blob())
        .then((result) => {
          const img = URL.createObjectURL(result);
          setAvatar(img);
        });
    }
  }, [url]);
  return (
    <div className="card">
      <div className="project-content">
        <div className="project-1st-line">
          {avatar && (
            <img
              className="avatar"
              src={avatar}
              width={24}
              height={24}
              alt="repository logo"
            />
          )}
          <div className="title">{name}</div>
        </div>
        <div className="project-2st-line">
          <div className={`badge ${mapLanguages(language)}`}>{language ?? "General"}</div>
          <span>{description}</span>
          <div className="actions">
            <a className="btn" target="__blank" href={url}>
              <span>See on Github </span>
              <GithubLogo size={16} />
            </a>
            {demo && (
              <a className="btn-2nd" target="__blank" href={demo}>
                <span>View Demo </span>
                <ArrowSquareOut size={16} />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const getReposLoader = () => {
  const RepoLoader = () => (
    <div className="card">
      <span className="loader" />
    </div>
  );
  const items = Array.from({ length: 10 }).map((_, index) => <RepoLoader key={index} />);
  return items;
};

function App() {
  const [repos, setRepos] = useState([]);
  const [error, setError] = useState(false);

  const fetchRepos = () => {
    fetch("https://api.github.com/users/zanzarone/repos", {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        let allRepos = result.map((repo) => {
          return {
            name: repo.name,
            description: repo.description,
            url: repo.html_url,
            language: repo.language,
          };
        });

        allRepos = allRepos.filter((repo) => repo.name !== "zanzarone");
        allRepos = allRepos.map((repo) => {
          if (repo.name === "avatr") {
            return { ...repo, demo: "https://samuelescatena.net/avatr" };
          }
          return repo;
        });

        setRepos(allRepos);
        setError(false);
      })
      .catch(() => setError(true));
  };

  useEffect(() => {
    let timer = setTimeout(() => fetchRepos(), 1000);
    return function clean() {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="App">
      <main>
        <div className="content">
          <div className="description">
            {/* <div> */}
            <img
              className="profile-img"
              src={me}
              alt="profile"
              width={150}
              height={150}
            />
            {/* </div> */}
            <div className="author-info">
              <div className="h1">
                Hi there, I'm <span className="primary">Samuele</span>{" "}
                <img
                  src="https://media.giphy.com/media/hvRJCLFzcasrR4ia7z/giphy.gif"
                  width="30px"
                  alt="hand five"
                />
              </div>
              <p>
                A passionate programmer driven by curiosity and always eager to improve. I
                love experimenting with new technologies and enjoy exploring IoT with
                Arduino. Constantly seeking growth, both personally and professionally
              </p>
              <p>Based in Lucca📍 Coffe addicted ☕️</p>
              <div className="contacts">
                <a target="__blank" href="https://github.com/zanzarone">
                  <GithubLogo size={28} />
                </a>
                <a target="__blank" href="https://it.linkedin.com/in/samuele-scatena">
                  <LinkedinLogo size={28} />
                </a>
                <a target="__blank" href="mailto:samuele.scatena@gmail.com">
                  <At size={28} />
                </a>
              </div>
            </div>
          </div>
          <div className="projects">
            <div className="projects-title">
              <div className="h2">
                Recent <span className="primary">projects</span>
              </div>
            </div>
            <div className="projects-list">
              {!error &&
                repos.length > 0 &&
                repos.map((repo) => (
                  <Repo
                    key={repo.url}
                    url={repo.url}
                    name={repo.name?.toLowerCase()}
                    language={repo.language}
                    description={repo.description}
                    demo={repo.demo}
                  />
                ))}
              {!error &&
                repos.length <= 0 &&
                getReposLoader().map((repoLoader) => repoLoader)}
              {error && (
                <div className="card-error">
                  <div className="h4">🤬 Ooops! </div>
                  <p>Unable to fetch repos. Please try again, or contact me, THX!</p>
                  <button onClick={fetchRepos} className="err">
                    <ArrowClockwise size={24} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
